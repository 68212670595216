import api from './index'
// axios
import request from '@/utils/request'

// 转发到大数据后台
export function forwardBG (data) {
  return request({
    url: api.ForwardBG,
    method: 'post',
    data,
    hideloading: true
  })
}

// 转发到竞价后台
export function forwardBID (data) {
  return request({
    url: api.ForwardBID,
    method: 'post',
    data,
    hideloading: true
  })
}

// 转发到大数据后台
export function oldForwardBG (data) {
  return request({
    url: api.OldForwardBG,
    method: 'post',
    data,
    hideloading: true
  })
}
// 转发到竞价后台
export function oldForwardBID (data) {
  return request({
    url: api.OldForwardBID,
    method: 'post',
    data,
    hideloading: true
  })
}

// 转发到竞价后台
export function forwardFile (data) {
  return request({
    url: api.ForwardFile,
    method: 'post',
    data,
    hideloading: false
  })
}
// 通用
export function gloubeURl (data, url) {
  return request({
    url: url,
    method: 'post',
    data,
    hideloading: true
  })
}
// 个人中心
export function selectCompanyCreditEnhancement (params) {
  return request({
    url: '/CompanyCreditEnhancement/selectCompanyCreditEnhancement',
    method: 'get',
    params,
    hideloading: true
  })
}
// 个人中心
export function selectCompanyCreditEnhancementCount (params) {
  return request({
    url: '/CompanyCreditEnhancement/selectCompanyCreditEnhancementCount',
    method: 'get',
    params,
    hideloading: true
  })
}
// 中标报告
export function getActiveRankingList (params) {
  return request({
    url: '/activeRanking/getActiveRankingList',
    method: 'get',
    params,
    hideloading: true
  })
}
// 资质
export function insertCredentials (data) {
  return request({
    url: '/CompanyCreditEnhancement/insertCredentials',
    method: 'post',
    data,
    hideloading: true
  })
}
// 荣誉
export function insertHonorImages (data) {
  return request({
    url: '/CompanyCreditEnhancement/insertHonorImages',
    method: 'post',
    data,
    hideloading: true
  })
}
// 人员
export function insertPersonnels (data) {
  return request({
    url: '/CompanyCreditEnhancement/insertPersonnels',
    method: 'post',
    data,
    hideloading: true
  })
}
// 业绩
export function insertAchievements (data) {
  return request({
    url: '/CompanyCreditEnhancement/insertAchievements',
    method: 'post',
    data,
    hideloading: true
  })
}
// 知识产权
export function insertIntellectualPropertyRights (data) {
  return request({
    url: '/CompanyCreditEnhancement/insertIntellectualPropertyRights',
    method: 'post',
    data,
    hideloading: true
  })
}
// 荣誉
export function insertTaxcredits (data) {
  return request({
    url: '/CompanyCreditEnhancement/insertTaxcredits',
    method: 'post',
    data,
    hideloading: true
  })
}
// 其他
export function insertOthers (data) {
  return request({
    url: '/CompanyCreditEnhancement/insertOthers',
    method: 'post',
    data,
    hideloading: true
  })
}
// 其他人员
export function getArtisanRelevance (data) {
  return request({
    url: '/artisanClassification/getArtisanRelevance',
    method: 'post',
    data,
    hideloading: true
  })
}
